<template>
  <div class="h-screen flex flex-col">
    <router-view
      class="flex-grow"
    />
    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/app/Footer.vue';

export default {
  name: 'FooterLayout',
  components: {
    Footer,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
